const GREY: string = 'gray'

export const countries = [
    { code: 'AFG', name: 'Afghanistan', code2: 'AF' },
    { code: 'ALB', name: 'Albania', code2: 'AL' },
    { code: 'DZA', name: 'Algeria', code2: 'DZ' },
    { code: 'AND', name: 'Andorra', code2: 'AD' },
    { code: 'AGO', name: 'Angola', code2: 'AO' },
    { code: 'ATA', name: 'Antarctica', code2: 'AQ' },
    { code: 'ARG', name: 'Argentina', code2: 'AR' },
    { code: 'ARM', name: 'Armenia', code2: 'AM' },
    { code: 'ABW', name: 'Aruba', code2: 'AW' },
    { code: 'AUS', name: 'Australia', code2: 'AU' },
    { code: 'AUT', name: 'Austria', code2: 'AT' },
    { code: 'AZE', name: 'Azerbaijan', code2: 'AZ' },
    { code: 'BHS', name: 'Bahamas', code2: 'BS' },
    { code: 'BHR', name: 'Bahrain', code2: 'BH' },
    { code: 'BGD', name: 'Bangladesh', code2: 'BD' },
    { code: 'BRB', name: 'Barbados', code2: 'BB' },
    { code: 'BLR', name: 'Belarus', code2: 'BY' },
    { code: 'BEL', name: 'Belgium', code2: 'BE' },
    { code: 'BLZ', name: 'Belize', code2: 'BZ' },
    { code: 'BEN', name: 'Benin', code2: 'BJ' },
    { code: 'BMU', name: 'Bermuda', code2: 'BM' },
    { code: 'BOL', name: 'Bolivia', code2: 'BO' },
    { code: 'BWA', name: 'Botswana', code2: 'BW' },
    { code: 'BRA', name: 'Brazil', code2: 'BR' },
    { code: 'BGR', name: 'Bulgaria', code2: 'BG' },
    { code: 'BDI', name: 'Burundi', code2: 'BI' },
    { code: 'KHM', name: 'Cambodia', code2: 'KH' },
    { code: 'CMR', name: 'Cameroon', code2: 'CM' },
    { code: 'CAN', name: 'Canada', code2: 'CA' },
    { code: 'TCD', name: 'Chad', code2: 'TD' },
    { code: 'CHL', name: 'Chile', code2: 'CL' },
    { code: 'CHN', name: 'China', code2: 'CN' },
    { code: 'COL', name: 'Colombia', code2: 'CO' },
    { code: 'COM', name: 'Comoros', code2: 'KM' },
    { code: 'COD', name: 'Congo', code2: 'CD' },
    { code: 'COG', name: 'Congo', code2: 'CG' },
    { code: 'CRI', name: 'Costa Rica', code2: 'CR' },
    { code: 'HRV', name: 'Croatia', code2: 'HR' },
    { code: 'CUB', name: 'Cuba', code2: 'CU' },
    { code: 'CYP', name: 'Cyprus', code2: 'CY' },
    { code: 'CZE', name: 'Czechia', code2: 'CZ' },
    { code: 'DNK', name: 'Denmark', code2: 'DK' },
    { code: 'DJI', name: 'Djibouti', code2: 'DJ' },
    { code: 'DMA', name: 'Dominica', code2: 'DM' },
    { code: 'ECU', name: 'Ecuador', code2: 'EC' },
    { code: 'EGY', name: 'Egypt', code2: 'EG' },
    { code: 'SLV', name: 'El Salvador', code2: 'SV' },
    { code: 'ERI', name: 'Eritrea', code2: 'ER' },
    { code: 'EST', name: 'Estonia', code2: 'EE' },
    { code: 'SWZ', name: 'Eswatini', code2: 'SZ' },
    { code: 'ETH', name: 'Ethiopia', code2: 'ET' },
    { code: 'FJI', name: 'Fiji', code2: 'FJ' },
    { code: 'FIN', name: 'Finland', code2: 'FI' },
    { code: 'FRA', name: 'France', code2: 'FR' },
    { code: 'GAB', name: 'Gabon', code2: 'GA' },
    { code: 'GMB', name: 'Gambia', code2: 'GM' },
    { code: 'GEO', name: 'Georgia', code2: 'GE' },
    { code: 'DEU', name: 'Germany', code2: 'DE' },
    { code: 'GHA', name: 'Ghana', code2: 'GH' },
    { code: 'GIB', name: 'Gibraltar', code2: 'GI' },
    { code: 'GRC', name: 'Greece', code2: 'GR' },
    { code: 'GRL', name: 'Greenland', code2: 'GL' },
    { code: 'GRD', name: 'Grenada', code2: 'GD' },
    { code: 'GLP', name: 'Guadeloupe', code2: 'GP' },
    { code: 'GUM', name: 'Guam', code2: 'GU' },
    { code: 'GTM', name: 'Guatemala', code2: 'GT' },
    { code: 'GGY', name: 'Guernsey', code2: 'GG' },
    { code: 'GIN', name: 'Guinea', code2: 'GN' },
    { code: 'GUY', name: 'Guyana', code2: 'GY' },
    { code: 'HTI', name: 'Haiti', code2: 'HT' },
    { code: 'HND', name: 'Honduras', code2: 'HN' },
    { code: 'HKG', name: 'Hong Kong', code2: 'HK' },
    { code: 'HUN', name: 'Hungary', code2: 'HU' },
    { code: 'ISL', name: 'Iceland', code2: 'IS' },
    { code: 'IND', name: 'India', code2: 'IN' },
    { code: 'IDN', name: 'Indonesia', code2: 'ID' },
    { code: 'IRN', name: 'Iran', code2: 'IR' },
    { code: 'IRQ', name: 'Iraq', code2: 'IQ' },
    { code: 'IRL', name: 'Ireland', code2: 'IE' },
    { code: 'IMN', name: 'Isle of Man', code2: 'IM' },
    { code: 'ISR', name: 'Israel', code2: 'IL' },
    { code: 'ITA', name: 'Italy', code2: 'IT' },
    { code: 'JAM', name: 'Jamaica', code2: 'JM' },
    { code: 'JPN', name: 'Japan', code2: 'JP' },
    { code: 'JEY', name: 'Jersey', code2: 'JE' },
    { code: 'JOR', name: 'Jordan', code2: 'JO' },
    { code: 'KAZ', name: 'Kazakhstan', code2: 'KZ' },
    { code: 'KEN', name: 'Kenya', code2: 'KE' },
    { code: 'KIR', name: 'Kiribati', code2: 'KI' },
    { code: 'PRK', name: 'Korea', code2: 'KP' },
    { code: 'KOR', name: 'Korea', code2: 'KR' },
    { code: 'KWT', name: 'Kuwait', code2: 'KW' },
    { code: 'KGZ', name: 'Kyrgyzstan', code2: 'KG' },
    { code: 'LVA', name: 'Latvia', code2: 'LV' },
    { code: 'LBN', name: 'Lebanon', code2: 'LB' },
    { code: 'LSO', name: 'Lesotho', code2: 'LS' },
    { code: 'LBR', name: 'Liberia', code2: 'LR' },
    { code: 'LBY', name: 'Libya', code2: 'LY' },
    { code: 'LTU', name: 'Lithuania', code2: 'LT' },
    { code: 'LUX', name: 'Luxembourg', code2: 'LU' },
    { code: 'MAC', name: 'Macao', code2: 'MO' },
    { code: 'MDG', name: 'Madagascar', code2: 'MG' },
    { code: 'MWI', name: 'Malawi', code2: 'MW' },
    { code: 'MYS', name: 'Malaysia', code2: 'MY' },
    { code: 'MDV', name: 'Maldives', code2: 'MV' },
    { code: 'MLI', name: 'Mali', code2: 'ML' },
    { code: 'MLT', name: 'Malta', code2: 'MT' },
    { code: 'NRU', name: 'Nauru', code2: 'NR' },
    { code: 'NPL', name: 'Nepal', code2: 'NP' },
    { code: 'NZL', name: 'New Zealand', code2: 'NZ' },
    { code: 'NIC', name: 'Nicaragua', code2: 'NI' },
    { code: 'NER', name: 'Niger', code2: 'NE' },
    { code: 'NGA', name: 'Nigeria', code2: 'NG' },
    { code: 'NIU', name: 'Niue', code2: 'NU' },
    { code: 'NOR', name: 'Norway', code2: 'NO' },
    { code: 'OMN', name: 'Oman', code2: 'OM' },
    { code: 'PAK', name: 'Pakistan', code2: 'PK' },
    { code: 'PLW', name: 'Palau', code2: 'PW' },
    { code: 'PAN', name: 'Panama', code2: 'PA' },
    { code: 'PRY', name: 'Paraguay', code2: 'PY' },
    { code: 'PER', name: 'Peru', code2: 'PE' },
    { code: 'PCN', name: 'Pitcairn', code2: 'PN' },
    { code: 'POL', name: 'Poland', code2: 'PL' },
    { code: 'PRT', name: 'Portugal', code2: 'PT' },
    { code: 'PRI', name: 'Puerto Rico', code2: 'PR' },
    { code: 'QAT', name: 'Qatar', code2: 'QA' },
    { code: 'ROU', name: 'Romania', code2: 'RO' },
    { code: 'RWA', name: 'Rwanda', code2: 'RW' },
    { code: 'REU', name: 'Reunion', code2: 'RE' },
    { code: 'LCA', name: 'Saint Lucia', code2: 'LC' },
    { code: 'WSM', name: 'Samoa', code2: 'WS' },
    { code: 'SMR', name: 'San Marino', code2: 'SM' },
    { code: 'SEN', name: 'Senegal', code2: 'SN' },
    { code: 'SRB', name: 'Serbia', code2: 'RS' },
    { code: 'SYC', name: 'Seychelles', code2: 'SC' },
    { code: 'SGP', name: 'Singapore', code2: 'SG' },
    { code: 'SVK', name: 'Slovakia', code2: 'SK' },
    { code: 'SVN', name: 'Slovenia', code2: 'SI' },
    { code: 'SOM', name: 'Somalia', code2: 'SO' },
    { code: 'SSD', name: 'South Sudan', code2: 'SS' },
    { code: 'ZAF', name: 'South Africa', code2: 'ZA' },
    { code: 'ESP', name: 'Spain', code2: 'ES' },
    { code: 'LKA', name: 'Sri Lanka', code2: 'LK' },
    { code: 'SDN', name: 'Sudan', code2: 'SD' },
    { code: 'SUR', name: 'Suriname', code2: 'SR' },
    { code: 'SWE', name: 'Sweden', code2: 'SE' },
    { code: 'CHE', name: 'Switzerland', code2: 'CH' },
    { code: 'TWN', name: 'Taiwan', code2: 'TW' },
    { code: 'TJK', name: 'Tajikistan', code2: 'TJ' },
    { code: 'THA', name: 'Thailand', code2: 'TH' },
    { code: 'TGO', name: 'Togo', code2: 'TG' },
    { code: 'TON', name: 'Tonga', code2: 'TO' },
    { code: 'TUN', name: 'Tunisia', code2: 'TN' },
    { code: 'TUR', name: 'Turkey', code2: 'TR' },
    { code: 'TUV', name: 'Tuvalu', code2: 'TV' },
    { code: 'UGA', name: 'Uganda', code2: 'UG' },
    { code: 'UKR', name: 'Ukraine', code2: 'UA' },
    { code: 'URY', name: 'Uruguay', code2: 'UY' },
    { code: 'UZB', name: 'Uzbekistan', code2: 'UZ' },
    { code: 'VUT', name: 'Vanuatu', code2: 'VU' },
    { code: 'VEN', name: 'Venezuela', code2: 'VE' },
    { code: 'VNM', name: 'Vietnam', code2: 'VN' },
    { code: 'YEM', name: 'Yemen', code2: 'YE' },
    { code: 'ZMB', name: 'Zambia', code2: 'ZM' },
    { code: 'ZWE', name: 'Zimbabwe', code2: 'ZW' }
]

export const keypadLetters = {
    'a': { value: "a", colour: GREY },
    'b': { value: "b", colour: GREY },
    'c': { value: "c", colour: GREY },
    'd': { value: "d", colour: GREY },
    'e': { value: "e", colour: GREY },
    'f': { value: "f", colour: GREY },
    'g': { value: "g", colour: GREY },
    'h': { value: "h", colour: GREY },
    'i': { value: "i", colour: GREY },
    'j': { value: "j", colour: GREY },
    'k': { value: "k", colour: GREY },
    'l': { value: "l", colour: GREY },
    'm': { value: "m", colour: GREY },
    'n': { value: "n", colour: GREY },
    'o': { value: "o", colour: GREY },
    'p': { value: "p", colour: GREY },
    'q': { value: "q", colour: GREY },
    'r': { value: "r", colour: GREY },
    's': { value: "s", colour: GREY },
    't': { value: "t", colour: GREY },
    'u': { value: "u", colour: GREY },
    'v': { value: "v", colour: GREY },
    'w': { value: "w", colour: GREY },
    'x': { value: "x", colour: GREY },
    'y': { value: "y", colour: GREY },
    'z': { value: "z", colour: GREY },
    'Enter': { value: "Enter", colour: GREY },
    'Backspace': { value: "Backspace", colour: GREY },
}